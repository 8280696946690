<template>
  <div class="fatal-vitoria-combo">
    <logo-fatal-white class="logo-fatal-white w-[]" />
    <separator-bar class="separator-bar h-[]" />
    <shield-vitoria class="shield-vitoria w-[]" />
  </div>
</template>

<script>
import LogoFatalWhite from "./LogoFatalWhite.vue";
import SeparatorBar from "./SeparatorBar.vue";
import ShieldVitoria from "./ShieldVitoria.vue";
export default {
  name: "fatal-vitoria-combo",
  components: {
    LogoFatalWhite,
    SeparatorBar,
    ShieldVitoria,
  },
};
</script>

<style lang="scss" scoped>
.fatal-vitoria-combo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 328px;
  .logo-fatal-white {
    width: 104px;
  }

  .separator-bar {
    height: 150px;
  }
  .shield-vitoria {
    // height: 147px;
    width: 127px;
  }
}
</style>
