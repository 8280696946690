<template>
  <section class="flex flex-col naming-disclaimer items-center justify-center mx-12 2xl:mx-0 mt-14 gap-5 relative">
    <div class="block 2xl:w-[280px]">
      <div class="flex flex-col justify-center gap-5 mb-[25px]">
        <h1 class="text-[24px]">
          Mas lembre-se, torcedor rubro-negro, se a proposta avançar...
        </h1>
        <div class="disclaimer-subtopic text-[16px] 2xl:text-[20px]">
          O escudo. As cores. Os mascotes.
        </div>

        <div class="disclaimer-title-highlig">
          Nada disso mudará.
        </div>

        <div class="disclaimer-subtopic text-[16px] 2xl:text-[20px]">
          Não se trata da mudança de escudo, cores e mascote.
        </div>
        <div class="disclaimer-subtopic text-[16px] 2xl:text-[20px]">
          Não se trata da aquisição do clube por outra empresa.
        </div>
      </div>
      <div class="flex flex-col justify-center gap-5">

        <div class="disclaimer-title-highlig">
          E não mudará também a importância da torcida.
        </div>
        <div class="disclaimer-subtopic text-[16px] 2xl:text-[20px]">
          Fundamentado
          no respeito e carinho que o torcedor sempre teve conosco, queremos, antes de iniciar essa conversa, saber a sua
          opinião sobre o projeto de negociação dos naming rights do clube.
        </div>

      </div>
    </div>
    <div class="hidden 2xl:content-block--middle">
      <img src="../../assets/images/player_bottom.png" />
    </div>

  </section>
</template>
<script>
export default {
  name: "partnership-progress",
  components: {},
  props: {
    bottomTitle: {
      type: String,
      default: "Renov<span>ação</span>",
    },
    ctaButtonLabel: {
      type: String,
      default: "Clique aqui e assista ao vídeo!",
    },
  },
};
</script>
<style lang="scss" scoped>
.naming-disclaimer {


  h1 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
    text-align: left;
  }
}

.disclaimer-title-highlig {
  color: #E30613;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.disclaimer-subtopic {
  color: #FFF;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-block {
  .header-text {
    color: #fff;
    text-align: center;
    font-family: Passion One;
    font-size: 48px;

    font-weight: 700;
    line-height: normal;

    &--stroked {
      -webkit-text-stroke: 1px #fff;
      -webkit-text-fill-color: transparent;
      color: transparent;
    }

    strong {
      font-weight: 700;
    }

    font-family: Passion One;
    font-size: 108px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;

    :deep(span) {
      color: var(--color-red);
    }
  }

  .stripe {
    position: absolute;
    width: 50%;
    height: 80px;
    z-index: -1;

    &--left {
      background-color: var(--color-red);
      left: 0;
      top: 350px;
    }

    &--right {
      background-color: var(--color-red);
      right: 0;
      top: 110px;
    }
  }

  &--top {
    strong {
      color: var(--color-red);
    }
  }

  &--middle {


    position: relative;
      left: 206px;
      top: -557px;
      width: 274px;
      height: auto;
      z-index: -1;
  }

  &--bottom {
    strong {
      color: var(--color-red);
    }
  }
}
</style>
