<template>
  <div class="big-footer px-10 py-11 2xl:px-[96px] 2xl:py-[65px] w-full items-center justify-center">
    <div class="">
        <ul class="big-footer__links text-lg leading-[40px] 2xl:leading-10 2xl:text-[30px] text-center">
            <li class="inline-block"><a href="http://socio-vitoria.futebolcard.com" target="_blank">Votação para Sócios-Torcedores</a></li>
            <li class="inline-block"><a href="https://twitter.com/intent/tweet?text=%40fatalmodel%20realiza%20proposta%20de%20R$%20200%20milh%C3%B5es%20para%20o%20%40ECVitoria...%20%23ColossalFatalModel" target="_blank">Compartilhe nas Redes Sociais</a></li>
            <li class="inline-block"><a href="#faq">Perguntas Frequentes</a></li>
            <li class="inline-block"><a href="https://www.youtube.com/watch?v=uGlIZ2p26a8&t" target="_blank">Sobre o Fatal Model</a></li>
        </ul>
    </div>
    <div class="flex justify-center mt-11 2xl:mt-[65px]">
        <fatal-vitoria-combo />
    </div>
  </div>
</template>
<script>
import FatalVitoriaCombo from "./Icons/FatalVitoriaCombo.vue";
export default {
  name: "big-footer",
  components: {
    FatalVitoriaCombo
  },
  props: {
    sponsorship: {
      type: String,
      default: "PATROCÍNIO",
    },
    team: {
      type: String,
      default: "E.C VITÓRIA",
    },
  },
};
</script>
<style lang="scss">
    .big-footer {
        background: var(--color-red);

        &__links {
            li:not(:first-child) {
                &:before{
                    display: inline-block;
                    margin-left: 15px;
                    margin-right: 15px;

                    @media (min-width: 1440px) {
                      content: "|";
                  }
                }

            }
        }

        .logo-fatal-white{
          width: 60px !important;
        }

        .shield-vitoria{
          width: 80px !important;
        }

        .separator-bar{
          height: 90px !important;
        }
    }
</style>
