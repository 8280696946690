<template>
  <header class="flex flex-col items-center justify-center">
    <div class="main-title flex items-center justify-center mb-1 2xl:mb-7 gap-2">
        <double-chevron />
        <h1 class="text-[20px] 2xl:text-[64px]">Vitória e Fatal Model</h1>
        <double-chevron class="mirrored "/>
    </div>
    <div class="sub-title uppercase text-center w-[162px] 2xl:w-auto text-sm 2xl:text-6xl tracking-[1.14px] leading-4 mb-[35px] 2xl:mb-16">
        juntos por mais conquistas
    </div>
    <div class="block-description text-center w-[232px] 2xl:w-[50%] text-sm 2xl:text-4xl mb-[35px]">
        Conheça a proposta para o novo passo da parceria Vitória e Fatal Model e participe desta decisão histórica!
    </div>
  </header>
</template>
<script>
import DoubleChevron from "./Icons/DoubleChevron.vue";
export default {
  name: "naming-rights-header",
  components: {
    DoubleChevron,
  }
};
</script>
<style lang="scss" scoped>
header {
  .main-title{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  svg {
    width: 96px;
    height: 96px;
  }
  h1 {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    em {
      font-style: normal;
      font-weight: 800;
      color: var(--color-red);
    }
  }
  .mirrored {
    transform: scaleX(-1);
  }
  @media (max-width: 1439px) {
    svg {
      width: 27px;
      height: 27px;
    }
  }
}
</style>
