<template>
  <div>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 273 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

        <path
          d="M130.18 309.326C108.762 297.952 89.7095 284.669 73.5583 269.87C57.2762 254.951 43.5133 238.08 32.6296 219.715C21.7567 201.372 13.5011 181.087 8.08105 159.418C2.7264 137.945 0 114.531 0 89.8185V78.4113L11.3091 76.8518C57.8542 70.4393 95.4567 49.2715 126.243 12.1488L136.32 0L146.397 12.1488C177.194 49.2715 214.786 70.4393 261.331 76.8518L272.64 78.4113V89.8185C272.64 114.52 269.914 137.945 264.548 159.418C259.128 181.087 250.872 201.383 240.01 219.715C229.138 238.069 215.364 254.94 199.082 269.87C182.931 284.669 163.889 297.952 142.471 309.326L136.331 312.587L130.191 309.326H130.18Z"
          fill="white"
        />
        <path
          d="M260.973 79.5236C213.73 73.013 175.571 51.5399 144.326 13.8719L136.322 4.22046L128.317 13.8719C97.0724 51.5399 58.9137 73.013 11.6706 79.5236L2.69531 80.7669V89.8294C2.69531 114.312 5.389 137.509 10.7109 158.775C16.0656 180.193 24.223 200.238 34.9541 218.352C45.6961 236.477 59.2954 253.141 75.3811 267.885C91.3578 282.531 110.225 295.673 131.436 306.949L136.311 309.545L141.186 306.949C162.397 295.673 181.264 282.531 197.24 267.885C213.326 253.141 226.925 236.477 237.667 218.352C248.398 200.238 256.556 180.193 261.911 158.775C267.232 137.509 269.926 114.312 269.926 89.8294V80.7669L260.951 79.5236H260.973Z"
          fill="black"
        />
        <path
          d="M134.512 301.169C113.78 290.154 95.3715 277.34 79.8092 263.065C64.2033 248.757 51.0075 232.595 40.5927 215.015C30.1778 197.446 22.2604 177.99 17.0693 157.182C11.8782 136.44 9.25 113.767 9.25 89.8185V86.4596L12.5762 86.0015C61.5205 79.251 101.021 57.0254 133.367 18.0379L136.333 14.4608L139.299 18.0379C171.634 57.0254 211.145 79.251 260.09 86.0015L263.416 86.4596V89.8185C263.416 113.767 260.788 136.429 255.597 157.182C250.395 177.99 242.477 197.446 232.073 215.015C221.658 232.584 208.463 248.757 192.857 263.065C177.283 277.34 158.875 290.165 138.143 301.169L136.333 302.129L134.523 301.169H134.512Z"
          fill="white"
        />
        <path
          d="M136.316 20.5025C106.097 56.9272 66.9456 82.3918 13.0938 89.8185C13.0938 116.188 16.2673 140.573 22.5925 162.984C22.6689 163.257 22.7452 163.529 22.8215 163.791H249.8C249.876 163.519 249.952 163.257 250.029 162.984C256.354 140.573 259.528 116.188 259.528 89.8185C205.676 82.3918 166.514 56.9272 136.305 20.5025"
          fill="#E10600"
        />
        <path
          d="M23.0462 164.555C39.7536 222.114 77.5088 266.522 136.323 297.767C195.137 266.522 232.892 222.114 249.599 164.555C249.676 164.304 249.741 164.053 249.817 163.802H22.8281C22.9045 164.053 22.9699 164.304 23.0462 164.555Z"
          fill="black"
        />
        <path
          d="M66.5662 117.595C63.8834 111.586 59.6629 106.341 54.3628 102.447L51.2656 100.179L76.0868 89.1315L75.7051 92.9484C75.0507 99.4918 76.1086 106.133 78.7914 112.153L95.1607 148.905V181.785L66.5771 117.584L66.5662 117.595Z"
          fill="white"
        />
        <path
          d="M194.404 110.845C196.673 105.174 197.545 98.9901 196.945 92.9484L196.564 89.1315L221.385 100.179L218.288 102.447C213.326 106.079 209.291 110.932 206.608 116.461L171.088 196.181C165.963 197.435 160.652 198.307 155.297 198.765L194.404 110.845Z"
          fill="white"
        />
        <path
          d="M122.641 243.555V224.623C124.854 225.015 127.079 225.353 129.304 225.626L136.316 241.385L142.892 226.618C144.059 226.651 145.073 226.662 146.033 226.662C149.937 226.662 153.863 226.498 157.735 226.16L136.316 274.276L122.641 243.555Z"
          fill="white"
        />
        <path
          d="M90.4531 121.009C107.64 112.382 126.333 107.998 146.039 107.998C159.584 107.998 172.758 110.092 185.223 114.214L179.737 126.527C168.984 123.113 157.653 121.379 146.039 121.379C128.285 121.379 111.425 125.382 95.9168 133.266L90.464 121.009H90.4531Z"
          fill="white"
        />
        <path
          d="M220.057 152.286C215.269 145.448 209.773 141.391 206.839 139.548L204.68 138.098L210.187 125.731C215.934 129.21 221.453 133.179 226.622 137.552L220.057 152.286Z"
          fill="white"
        />
        <path
          d="M146.033 219.595C145.804 219.595 145.575 219.595 145.346 219.595H144.888C142.892 219.573 140.875 219.508 138.901 219.399C137.123 219.301 135.367 219.17 133.666 219.006C131.529 218.799 129.38 218.537 127.254 218.221C125.716 217.992 124.167 217.73 122.641 217.447V203.793C123.873 204.055 125.127 204.295 126.359 204.513C132.543 205.603 138.945 206.17 145.39 206.214H145.662C145.782 206.214 145.902 206.214 146.033 206.214C147.843 206.214 149.665 206.17 151.584 206.083C157.462 205.8 163.297 205.069 168.935 203.902C171.269 203.411 173.581 202.855 175.816 202.244C185.097 199.682 194.061 195.854 202.459 190.87L202.502 190.848C205.469 189.136 211.02 185.33 216.058 178.546L222.623 193.291C206.33 206.018 187.932 214.24 167.953 217.73C165.794 218.112 163.613 218.428 161.465 218.69C156.404 219.301 151.213 219.606 146.033 219.606"
          fill="white"
        />
        <path
          d="M95.1498 208.82C64.6468 195.09 48.8337 172.832 48.1684 171.883L46.2818 169.189C44.9076 167.226 43.2718 165.58 41.4179 164.315L41.3851 164.293L40.5781 163.802L41.407 163.3H41.4288C43.2827 162.024 44.9077 160.389 46.2927 158.426L48.1902 155.71C48.8773 154.729 55.148 145.993 67.1333 136.178L72.8042 148.916C63.8507 156.735 59.2049 163.311 59.1613 163.377L58.8668 163.802L59.1613 164.227C59.2703 164.391 70.5794 180.335 92.347 192.418C93.0014 192.778 93.6666 193.138 94.3427 193.498C94.6263 193.64 94.8989 193.793 95.1825 193.934V208.82H95.1498Z"
          fill="white"
        />
        <path
          d="M102.205 97.9105C102.205 91.3235 100.482 84.8237 97.2101 79.1092L95.3125 75.7721H173.288V96.0456L169.95 94.148C164.236 90.8873 157.736 89.1533 151.149 89.1533H115.575V104.421C111.027 105.49 106.534 106.799 102.194 108.325V97.9105H102.205Z"
          fill="white"
        />
        <path
          d="M97.2101 243.882C98.0607 242.388 98.8241 240.807 99.4784 239.182C101.289 234.667 102.205 229.923 102.205 225.081V138.01C106.545 135.982 111.038 134.248 115.586 132.874V157.88H133.002C139.578 157.88 146.078 156.157 151.804 152.886L155.141 150.977V178.143L151.804 176.235C146.089 172.974 139.589 171.24 133.002 171.24H115.586V247.197H95.3125L97.221 243.871L97.2101 243.882Z"
          fill="white"
        />
        <path
          d="M156.094 247.219L162.528 232.769C165.985 231.929 169.29 230.61 172.376 228.854L175.713 226.956V247.23H156.105L156.094 247.219Z"
          fill="white"
        />

    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
