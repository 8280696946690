<template>
  <section>
    <div class="footer-wrapper flex flex-col">
      <h1 class="footer-text">{{ text }}</h1>
      <div class="footer-since" v-html="since"></div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    since: {
      type: String,
      required: true,
    },
  },
  name: "sponsorship-footer",
};
</script>
<style lang="scss">
.footer-wrapper {
  width: auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;

  @media screen and (min-width: 1440px) {
    width: 1180px;
    gap: 16px;
    align-items: start;
    margin-bottom: 60px;
  }
}

.footer-text {
  width: 250px;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  background-color: #e10600;

  text-align: center;

  @media screen and (min-width: 1440px) {
    width: 644px;
    color: #fff;
    font-size: 43px;
    font-weight: 800;
    line-height: normal;
    padding: 0 4px;
  }
}

.footer-since {
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  gap: 8px;
  span {
    color: #e10600;
  }

  @media screen and (min-width: 1440px) {
    font-size: 16px;
  }
}
</style>
