<template>
  <li class="card-item flex justify-center align-middle">
    <a :href="link" target="_blank" rel="noopener noreferrer" class="no-underline">
      <div
        :class="{'block': true, 'red-title': pkey === 6}"
        :style="{ 'background-image': `url(${image})` }"
        :data-title="title"
      >
        <p>{{ text }}</p>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: "CampaignCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    textLong: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    pkey: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  flex-basis: 50%;
  &:not(:nth-child(5n + 4)):not(:nth-child(5n + 0)) {
    flex-basis: 33.33%;
  }

  &:not(:nth-child(5n + 1)):not(:nth-child(5n + 2)):not(:nth-child(5n
        + 3)):not(:nth-child(5n + 5)) {
    justify-content: flex-end;
    padding-right: 72px;
  }

  &:not(:nth-child(5n + 1)):not(:nth-child(5n + 2)):not(:nth-child(5n
        + 3)):not(:nth-child(5n + 4)) {
    padding-left: 72px;
    justify-content: flex-start;
  }

  .block {
    position: relative;
    width: 295px;
    height: 166px;
    cursor: pointer;
    border-radius: 27px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    p {
      display: none;
      position: absolute;
      font-family: Montserrat, sans-serif;
      z-index: 1;
      background: #000000bf;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      color: #fff;
      width: 295px;
      height: 166px;
      border-radius: 27px;
      padding: 16px 12px;
      align-items: flex-end;
    }

    &::after {
      content: attr(data-title);
      position: absolute;
      background: black;
      top: 106px;
      left: 0px;
      z-index: 2;
      min-height: 37px;
      padding: 10px 12px 10px 9px;
      border-radius: 17px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 13px;
      font-family: Montserrat, sans-serif;
      font-weight: 800;
      line-height: normal;
    }

    &.red-title {
      &::after {
        background: var(--color-red);
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 295px;
      height: 166px;
      background: black;
      top: 11px;
      left: 8px;
      z-index: -2;
      border-radius: 27px;
      border: 1px solid #fff;
    }

    &:hover {
      p {
        display: flex;
        animation: fade-in 0.5s ease-out;
        animation-fill-mode: both;
      }
      &::after {
        display: none;
      }
    }
  }
  // animation fade-in
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
