<template>
  <main>
    <div class="content-wrapper container pt-8">
      <sponsorship-header
        :sponsorship="textData.sponsorship"
        :team="textData.team"
      ></sponsorship-header>

      <welcome-banner></welcome-banner>

      <bg-shield class="hidden 2xl:block opacity-30 -z-20" />

      <partnership-overview-desktop
        :title="textData.welcome"
        :cardText="textData.welcomeText"
        :description="textData.overviewHtml"
      />

      <name-presentation />

      <naming-disclaimer class="block 2xl:hidden"></naming-disclaimer>
      <naming-disclaimer-desktop
        class="hidden 2xl:block"
      ></naming-disclaimer-desktop>

      <call-for-vote class="call-for-vote"> </call-for-vote>
      <marketing-campaigns-grid
        class="hidden 2xl:block"
        :campaigns="textData.actionList"
      ></marketing-campaigns-grid>
      <marketing-campaigns-grid-mobile
        class="2xl:hidden"
        :title="textData.actionsTitle"
        :campaigns="textData.actionList"
      ></marketing-campaigns-grid-mobile>
      <partnership-stats :stats="statsData" />
      <sponsorship-footer
        :text="textData.footerText"
        :since="textData.footerSince"
      ></sponsorship-footer>
      <big-footer></big-footer>
      <lion-background class="hidden 2xl:block"></lion-background>
    </div>
  </main>
</template>
<script>
import SponsorshipHeader from "./components/SponsorshipHeader.vue";
import WelcomeBanner from "./components/WelcomeBanner/index.vue";
import BgShield from "./components/Icons/BgShield.vue";

import NamePresentation from "./components/NamePresentation/index.vue";

import PartnershipOverviewDesktop from "./components/PartnershipOverview/desktop.vue";
import MarketingCampaignsGrid from "./components/MarketingCampaignsGrid/index.vue";
import MarketingCampaignsGridMobile from "./components/MarketingCampaignsGrid/mobile.vue";

import NamingDisclaimer from "./components/NamingDisclaimer/index.vue";
import NamingDisclaimerDesktop from "./components/NamingDisclaimer/desktop.vue";

import CallForVote from "./components/CallForVote/index.vue";

import PartnershipStats from "./components/PartnershipStats/index.vue";
import SponsorshipFooter from "./components/SponsorshipFooter.vue";
import BigFooter from "./components/SponsorshipBigFooter.vue";

import LionBackground from "./components/LionBackground.vue";

import textData from "./data/textData.js";
import statsData from "./data/statsData.js";

export default {
  name: "App",
  components: {
    SponsorshipHeader,
    WelcomeBanner,
    BgShield,
    NamePresentation,
    PartnershipOverviewDesktop,
    MarketingCampaignsGrid,
    CallForVote,
    MarketingCampaignsGridMobile,

    PartnershipStats,
    SponsorshipFooter,
    LionBackground,
    NamingDisclaimerDesktop,
    NamingDisclaimer,
    BigFooter,
  },
  data() {
    return {
      textData: textData,
      statsData: statsData,
    };
  }
};
</script>
<style lang="scss">
:root {
  --color-red: #e10600;
  --color-blackish: #050505;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  position: relative;
}

html {
  overflow-x: hidden;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
    background-image: url(./assets/images/bg.jpg);
    background-color: #51515180;
    background-repeat: repeat;
    background-size: 100%;
    mix-blend-mode: luminosity;
  }
}

main {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  width: 100%;
  overflow: hidden;
}

@media screen and (min-width: 1440px) {
  .content-wrapper {
    margin-top: 60px;
    flex-flow: column;
    display: flex;
    align-items: center;
  }

  .showMobileOnly {
    display: none;
  }
}
</style>
