<template>
  <div class="statistic-cell">
    <div class="statistic-icon">
      <component :is="statistic.icon"></component>
    </div>
    <div class="statistic-grid">
      <div class="statistic-result">{{ checkResut(statistic.result) }}</div>
      <div class="statistic-title" v-html="statistic.title"></div>
    </div>
  </div>
</template>
<script>
import IconFinance from "./icons/IconFinance.vue";
import IconDate from "./icons/IconDate.vue";
import IconBalls from "./icons/IconBalls.vue";
import IconHanger from "./icons/IconHanger.vue";
import IconTicket from "./icons/IconTicket.vue";
import IconPlayer from "./icons/IconPlayer.vue";

export default {
  props: {
    statistic: {
      type: Object,
      required: true,
    },
    totalDays: {
      type: Number,
      required: true,
    },
  },
  name: "statistic-cell",
  components: {
    IconPlayer,
    IconTicket,
    IconHanger,
    IconBalls,
    IconDate,
    IconFinance,
  },
  methods: {
    checkResut(result) {
      return result === "totalDays" ? this.totalDays : result;
    },
  },
};
</script>
<style lang="scss">
.statistic-cell {
  @apply flex items-center gap-0 gap-x-[6px];

  @media screen and (min-width: 1440px) {
    @apply h-[104px] gap-[16px] w-[564px];
  }
}

.statistic-result {
  color: #e10600;
  font-size: 12px;
  font-weight: 800;
  line-height: normal;
  @media screen and (min-width: 1440px) {
    color: #e10600;
    font-size: 32px;
  }
}

.statistic-title {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  & span {
    color: #e10600;
  }
  @media screen and (min-width: 1440px) {
    color: #fff;
    font-size: 20px;

  }
}

.statistic-grid {
  @apply flex flex-col  w-[225px];

  @media screen and (min-width: 1440px) {
    @apply gap-[6px] w-[442px];
  }
}

.statistic-icon {
  background-color: #e10600;
  @apply flex items-center justify-center w-[40px] h-[40px];
  & svg {
    width: 26px;
    height: 26px;
  }
  &:hover {
    background-color: #fff;
    color: #e10600;
    transition: all 1s ease-in-out;
  }
  @media screen and (min-width: 1440px) {
    @apply flex items-center justify-center w-[104px] h-[104px];
    &:hover {
      background-color: #fff;
      color: #e10600;
      transition: all 1s ease-in-out;
    }
    & svg {
      width: auto;
      height: auto;
    }
  }
}
</style>
