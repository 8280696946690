<template>
  <div class="hidden 2xl:inline-block">
    <div class="block">
      <div class="block-bg">
        <svg
          width="1175"
          height="1363"
          viewBox="0 0 1175 1363"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.4" clip-path="url(#clip0_1_19)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M580.223 1341.13L580.155 1341.23L569.754 1335.77L567.42 1334.57L554.583 1327.95L554.651 1327.81C319.596 1201.16 139.804 978.991 58.4548 715.592L41.3268 653.038C24.0272 580.597 15.3774 507.297 15.3431 436.332C15.3431 416.625 18.398 364.645 18.7755 357.916H18.8099V357.642L19.8739 339.583L57.5967 334.227C240.101 308.134 405.545 219.316 552.969 59.7042L587.088 22.6595L621.55 59.3953C772.338 219.591 934.899 308.169 1117.23 334.261L1154.92 339.583L1155.64 351.359L1156.02 357.607V358.019C1156.02 358.466 1156.09 359.152 1156.12 360.011C1156.19 361.727 1156.36 364.199 1156.5 367.22C1156.84 373.297 1157.29 381.606 1157.73 390.567C1158.63 408.591 1159.55 429.053 1159.55 440.383C1159.55 510.009 1151.62 581.936 1135.56 653.073L1119.22 715.627C1036.53 983.42 852.005 1208.64 611.184 1334.09L588.976 1345.7L580.155 1341.16L580.223 1341.13Z"
              fill="white"
            />
            <path
              d="M589.113 1363L586.024 1361.39L585.887 1361.59L534.057 1334.61L534.297 1334.13C303.018 1204.9 124.667 981.91 43.7982 720.09L26.5329 657.055C8.89007 583.172 0 509.082 0 436.298C0 418.136 2.47137 374.602 3.29516 360.285L5.28599 326.056L55.4686 318.915C236.771 292.994 400.362 202.253 541.711 49.1643L587.019 -0.0341797L632.809 48.7523C779.546 204.656 938.744 293.028 1119.43 318.915L1169.54 325.987L1171.43 357.195C1171.53 358.054 1171.57 358.946 1171.57 359.873C1171.57 360.663 1171.67 361.693 1171.7 362.86L1171.91 366.294C1172.22 371.684 1172.6 378.997 1173.01 386.996L1173.15 389.64C1174 406.978 1174.97 428.401 1174.97 440.246C1174.97 512.138 1166.76 584.82 1150.6 656.334L1134.15 719.403C1050.02 991.9 862.097 1220.66 618.324 1347.65L589.079 1362.97L589.113 1363ZM581.39 1324.44L589.01 1328.29L604.147 1320.39C840.713 1197.17 1023.11 975.043 1104.63 711.026L1120.77 649.125C1136.32 580.322 1144.28 509.906 1144.28 440.314C1144.28 429.122 1143.32 408.214 1142.49 391.253L1142.36 388.644C1141.95 380.748 1141.57 373.469 1141.26 368.01L1141.09 364.92C1140.95 362.929 1140.85 361.247 1140.85 359.908L1140.4 352.973L1115.17 349.402C927.245 322.485 762.178 231.058 610.429 69.8324L587.294 45.1817L564.4 70.0728C418.039 228.483 248.304 322.485 59.7935 349.436L34.5306 353.007L33.4665 371.375C31.6816 403.956 30.7549 425.757 30.7549 436.263C30.7549 506.61 39.3703 578.331 56.3267 649.399L73.3174 711.472C153.225 970.065 331.37 1189.93 561.963 1314.18L581.356 1324.44H581.39Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M588.976 1313.18L597.935 1308.51C831.582 1186.81 1011.34 967.696 1091.76 707.078L1092.35 705.189L1099.49 681.843H1099.01L1103.4 663.99C1121.8 589.317 1130.79 513.442 1130.79 440.349C1130.79 423.869 1128.46 380.713 1127.56 364.817L1113.18 362.792C923.95 335.841 754.592 243.04 600.543 79.1364L587.294 65.0258L574.182 79.2738C423.257 242.902 251.016 335.806 61.6813 362.757L47.3336 364.783C47.265 365.847 47.2307 367.014 47.1277 368.285C46.7845 374.087 46.3726 381.949 45.9263 390.429C45.0682 407.355 44.2444 426.993 44.2444 436.263C44.2444 510.593 54.0956 587.807 73.7636 663.75L78.3974 681.775H78.2944L86.0518 707.009C166.509 967.696 346.232 1186.77 579.949 1308.48L580.738 1308.89L588.976 1313.15V1313.18ZM580.258 1341.13L580.189 1341.23L569.789 1335.77L567.454 1334.57L554.617 1327.95L554.686 1327.81C319.631 1201.16 139.838 978.991 58.4892 715.592L41.3612 653.038C24.0616 580.597 15.4118 507.297 15.3774 436.332C15.3774 416.625 18.4323 364.645 18.8099 357.916H18.8442V357.642L19.9083 339.583L57.631 334.227C240.135 308.134 405.58 219.316 553.004 59.7042L587.122 22.6595L621.584 59.3953C772.372 219.591 934.934 308.169 1117.27 334.261L1154.95 339.583L1155.68 351.359L1156.05 357.607V358.019C1156.05 358.466 1156.12 359.152 1156.16 360.011C1156.22 361.727 1156.4 364.199 1156.53 367.22C1156.88 373.297 1157.32 381.606 1157.77 390.567C1158.66 408.591 1159.59 429.053 1159.59 440.383C1159.59 510.009 1151.66 581.936 1135.6 653.073L1119.26 715.627C1036.57 983.42 852.04 1208.64 611.218 1334.09L589.01 1345.7L580.189 1341.16L580.258 1341.13Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1070.55 699.765H105.308L105.891 701.653C184.495 956.4 360.305 1170.57 588.186 1289.22L588.873 1289.56L589.559 1289.22C817.475 1170.57 993.286 956.4 1071.89 701.653L1072.47 699.765H1070.52H1070.55Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1071.96 701.447C1103.6 595.051 1115.07 484.329 1107.9 382.224C915.3 354.895 742.75 260 587.466 94.6204C435.064 260 259.7 354.895 67.036 382.224C59.8621 484.294 72.7682 595.016 105.857 701.447H1071.96Z"
              fill="#E30613"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M210.89 418.17L321.587 360.663C320.146 395.167 324.471 436.881 340.294 469.943L448.108 702.889V833.73L287.125 498.68C271.302 467.059 238.247 444.022 210.925 418.135"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M975.711 428.229L864.98 370.722C866.422 405.226 860.69 446.906 846.273 480.003L673.758 869.676L741.343 859.617L899.476 508.773C913.858 477.119 948.389 454.116 975.677 428.229"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M438.016 1078.18H537.18V735.986H640.703C662.293 735.986 675.199 763.314 691.023 777.7L692.465 636.765C675.165 649.708 663.701 674.153 642.11 674.153H538.622L537.18 534.694L471.071 554.813V1022.15C471.071 1043.71 449.515 1059.5 438.016 1078.18Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M472.513 449.824V354.895C472.513 333.334 452.398 317.507 439.458 300.272H747.144V395.167C722.705 379.374 702.59 350.603 675.234 350.603H538.69V436.847L472.547 449.79L472.513 449.824Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M682.442 1079.62H784.489V984.725C780.164 989.085 777.315 1027.88 702.556 1027.88L682.442 1079.62Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M560.212 990.493V1083.98L609.09 1180.28L703.998 966.048L645.028 967.49L601.916 1076.77L560.212 990.493Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M448.073 859.617C368.99 825.113 295.672 767.606 243.945 701.447C259.734 668.385 291.382 635.323 318.704 619.496L287.091 561.989C248.27 590.725 206.565 622.379 187.893 665.501C180.685 681.328 167.779 692.83 154.804 701.447C167.744 708.657 176.36 707.215 189.335 725.892C252.595 816.461 348.91 886.946 448.108 922.892V859.617H448.073Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M377.639 503.04L406.403 559.105C538.656 500.156 668.026 498.714 787.372 543.277L810.37 492.946C680.966 436.847 522.832 441.173 377.605 503.005"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M906.65 541.835L885.129 592.167L925.391 612.286C936.89 616.577 941.215 632.405 948.389 642.464L984.327 563.362C971.352 563.362 957.039 569.095 945.506 563.362L906.65 541.801V541.835Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M558.77 881.212V935.869C703.998 967.49 840.575 924.368 967.096 849.592L929.682 793.493C840.575 869.711 688.14 907.133 558.77 881.246"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_19">
              <rect width="1175" height="1363" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bg-shield",
};
</script>

<style lang="scss" scoped>
.block {
  position: relative;
}

.block-bg {
  position: absolute;
  top: -35px;
  right: 89px;
  z-index: -2;
}
</style>
