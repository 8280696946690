<template>
    <div>
        <svg
            :width="width"
            :height="height"
            viewBox="0 0 370 430"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M183.073 422.338L183.051 422.371L179.783 420.653L179.049 420.275L175.015 418.191L175.037 418.148C101.165 378.309 44.6615 308.426 19.0957 225.573L13.7128 205.896C8.27606 183.109 5.55766 160.053 5.54688 137.73C5.54688 131.531 6.50694 115.181 6.6256 113.064H6.63639V112.978L6.9708 107.298L18.826 105.613C76.1819 97.4053 128.177 69.4672 174.508 19.2607L185.23 7.60815L196.061 19.1635C243.449 69.5536 294.538 97.4161 351.84 105.624L363.684 107.298L363.911 111.002L364.03 112.967V113.097C364.03 113.237 364.051 113.453 364.062 113.723C364.083 114.263 364.137 115.041 364.181 115.991C364.288 117.903 364.429 120.516 364.569 123.335C364.849 129.004 365.141 135.441 365.141 139.005C365.141 160.906 362.649 183.531 357.6 205.907L352.466 225.584C326.479 309.819 268.487 380.663 192.803 420.124L185.824 423.774L183.051 422.349L183.073 422.338Z"
                fill="white"
            />
            <path
                d="M185.861 429.217L184.89 428.71L184.847 428.775L168.558 420.286L168.633 420.135C95.9488 379.486 39.8981 309.344 14.4833 226.987L9.05731 207.16C3.51265 183.919 0.71875 160.614 0.71875 137.72C0.71875 132.007 1.49543 118.313 1.75433 113.81L2.37999 103.043L18.151 100.796C75.1294 92.6427 126.542 64.0999 170.964 15.9453L185.203 0.469727L199.593 15.8157C245.709 64.8559 295.74 92.6535 352.524 100.796L368.274 103.021L368.867 112.838C368.899 113.108 368.91 113.388 368.91 113.68C368.91 113.928 368.942 114.252 368.953 114.62L369.018 115.7C369.115 117.395 369.234 119.695 369.363 122.212L369.406 123.043C369.676 128.497 369.978 135.236 369.978 138.961C369.978 161.575 367.4 184.438 362.319 206.933L357.152 226.771C330.712 312.486 271.652 384.443 195.041 424.39L185.85 429.207L185.861 429.217ZM183.434 417.09L185.828 418.299L190.586 415.815C264.931 377.056 322.255 307.184 347.875 224.136L352.945 204.665C357.831 183.023 360.334 160.873 360.334 138.983C360.334 135.462 360.032 128.886 359.773 123.551L359.73 122.73C359.601 120.246 359.482 117.957 359.385 116.239L359.331 115.268C359.288 114.641 359.255 114.112 359.255 113.691L359.115 111.509L351.187 110.386C292.126 101.919 240.25 73.1606 192.56 22.4465L185.289 14.6926L178.094 22.5221C132.097 72.3506 78.7539 101.919 19.5102 110.397L11.5707 111.52L11.2363 117.298C10.6754 127.546 10.3841 134.404 10.3841 137.709C10.3841 159.837 13.0918 182.397 18.4207 204.751L23.7604 224.277C48.8731 305.618 104.859 374.777 177.328 413.861L183.423 417.09H183.434Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M185.821 413.547L188.636 412.079C262.065 373.795 318.558 304.873 343.833 222.895L344.016 222.301L346.26 214.957H346.109L347.49 209.341C353.272 185.853 356.098 161.986 356.098 138.994C356.098 133.81 355.364 120.235 355.084 115.235L350.564 114.598C291.094 106.12 237.869 76.9296 189.456 25.3732L185.292 20.9346L181.172 25.4164C133.74 76.8864 79.6093 106.11 20.1067 114.587L15.5976 115.224C15.5761 115.559 15.5653 115.926 15.5329 116.326C15.425 118.151 15.2956 120.624 15.1554 123.292C14.8857 128.616 14.6268 134.793 14.6268 137.709C14.6268 161.089 17.7227 185.377 23.9038 209.266L25.3601 214.935H25.3278L27.7657 222.873C53.0511 304.873 109.533 373.784 182.984 412.068L183.232 412.197L185.821 413.537V413.547ZM183.081 422.338L183.059 422.371L179.791 420.653L179.057 420.275L175.023 418.191L175.044 418.148C101.173 378.309 44.6693 308.426 19.1035 225.573L13.7207 205.896C8.28387 183.109 5.56547 160.053 5.55469 137.73C5.55469 131.531 6.51475 115.181 6.63341 113.064H6.6442V112.978L6.97861 107.298L18.8338 105.613C76.1898 97.4053 128.184 69.4672 174.516 19.2607L185.238 7.60815L196.069 19.1635C243.457 69.5536 294.546 97.4161 351.848 105.624L363.692 107.298L363.919 111.002L364.037 112.967V113.097C364.037 113.237 364.059 113.453 364.07 113.723C364.091 114.263 364.145 115.041 364.188 115.991C364.296 117.903 364.436 120.516 364.577 123.335C364.857 129.004 365.148 135.441 365.148 139.005C365.148 160.906 362.657 183.531 357.608 205.907L352.473 225.584C326.487 309.819 268.495 380.663 192.811 420.124L185.832 423.774L183.059 422.349L183.081 422.338Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M337.169 220.594H33.8203L34.0037 221.188C58.7066 301.32 113.959 368.687 185.576 406.009L185.791 406.117L186.007 406.009C257.635 368.687 312.887 301.32 337.59 221.188L337.773 220.594H337.158H337.169Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M337.603 221.123C347.549 187.656 351.151 152.828 348.897 120.71C288.37 112.114 234.142 82.2644 185.34 30.2437C137.445 82.2644 82.3326 112.114 21.7836 120.71C19.5291 152.817 23.5851 187.645 33.984 221.123H337.603Z"
                fill="#E30613"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M67 132.017L101.789 113.928C101.336 124.782 102.695 137.903 107.668 148.303L141.551 221.577V262.734L90.9585 157.342C85.9856 147.396 75.5975 140.149 67.0108 132.007"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M307.357 135.182L272.557 117.093C273.01 127.946 271.208 141.057 266.678 151.467L212.461 274.041L233.701 270.876L283.398 160.517C287.918 150.56 298.77 143.325 307.346 135.182"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M138.375 339.625H169.539V231.987H202.074C208.859 231.987 212.915 240.584 217.888 245.109L218.341 200.777C212.904 204.848 209.301 212.538 202.516 212.538H169.993L169.539 168.67L148.763 174.999V322.001C148.763 328.783 141.989 333.75 138.375 339.625Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M149.216 141.974V112.114C149.216 105.332 142.895 100.353 138.828 94.9321H235.525V124.782C227.845 119.814 221.523 110.764 212.926 110.764H170.014V137.892L149.227 141.964L149.216 141.974Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M215.188 340.079H247.258V310.229C245.899 311.601 245.004 323.804 221.509 323.804L215.188 340.079Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M176.773 312.044V341.45L192.135 371.743L221.961 304.354L203.429 304.808L189.88 339.183L176.773 312.044Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M141.541 270.876C116.688 260.023 93.646 241.934 77.3896 221.123C82.3517 210.723 92.2976 200.324 100.884 195.345L90.9492 177.256C78.7487 186.295 65.6422 196.252 59.7739 209.816C57.5086 214.795 53.4526 218.413 49.375 221.123C53.4418 223.391 56.1494 222.938 60.227 228.813C80.1079 257.301 110.377 279.473 141.552 290.78V270.876H141.541Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M119.401 158.714L128.441 176.349C170.005 157.806 210.662 157.353 248.169 171.371L255.397 155.539C214.729 137.892 165.032 139.253 119.391 158.703"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M285.654 170.917L278.891 186.749L291.544 193.077C295.158 194.427 296.517 199.406 298.772 202.57L310.066 177.688C305.988 177.688 301.49 179.492 297.865 177.688L285.654 170.906V170.917Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M176.32 277.669V294.862C221.961 304.808 264.884 291.244 304.646 267.723L292.888 250.077C264.884 274.051 216.978 285.823 176.32 277.68"
                fill="white"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "OfficialShield",
    props: {
      width: {
        type: Number,
        default: 370
      },
      height: {
        type: Number,
        default: 430
      }
    }
};
</script>

<style lang="scss" scoped></style>
