<template>
  <div class="animantion-group">
    <logo-fatal-white class="logo-fatal-white" />
    <separator-bar class="separator-bar" />
    <shield-vitoria class="shield-vitoria" />
  </div>
</template>

<script>
import LogoFatalWhite from "./LogoFatalWhite.vue";
import SeparatorBar from "./SeparatorBar.vue";
import ShieldVitoria from "./ShieldVitoria.vue";
export default {
  name: "animantion-fatal-vitoria",
  components: {
    LogoFatalWhite,
    SeparatorBar,
    ShieldVitoria,
  },
};
</script>

<style lang="scss" scoped>
.animantion-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 17px;
  max-width: 240px;
  .logo-fatal-white {
    height: 88px;
    animation: fade-in-down 1s ease-in-out;
    animation-fill-mode: both;
    opacity: 0;
  }

  .separator-bar {
    height: 94px;
  }
  .shield-vitoria {
    height: 80px;
    animation: fade-in-up 1s ease-in-out;
    animation-fill-mode: both;
    opacity: 0;
  }

  @media (min-width: 1440px) {
    max-width: 750px;
    .logo-fatal-white {
      height: 300px;
    }
    .separator-bar {
      height: 316px;
    }
    .shield-vitoria {
      height: 336px;
      width: 260px;
    }
  }

  @keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fade-in-down {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
