<template>
  <section class="w-full px-[20px] 2xl:px-[86px] mt-12 2xl:mt-[235px]">
    <div class="partnership-wrapper block">
      <div class="partnership">
        <h1 class="partnership-title text-2xl">
          ENTENDA A PROPOSTA
        </h1>
        <div class="grid grid-cols-1 2xl:grid-cols-6 mb-12 2xl:mb-24 relative">
          <div class="2xl:col-span-4">
            <h2 class="text-white text-2xl 2xl:text-[64px] w-[211px] 2xl:w-auto max-w-[550px] 2xl:pr-2 mb-2 2xl:mb-9">
              Fatal Model oferece <br v-if="isDesktop">R$ 200 milhões para o Vitória lutar na
              Série A
            </h2>
            <div>
              <p class="text-white text-[14px] 2xl:text-[40px] max-w-[680px] mb-2">
                Para tornar o Leão ainda maior, o Fatal Model quer investir R$
                200 milhões, durante uma década, para unir seu nome ao Esporte
                Clube Vitória.
              </p>
              <p class="text-white text-[14px] 2xl:text-[40px] max-w-[680px]">
                Trata-se de algo nunca antes visto no futebol brasileiro. Um
                formato de patrocínio inédito e disruptivo.
              </p>
            </div>
          </div>
          <div class="hidden 2xl:block 2xl:col-span-2 2xl:overflow-hidden  ">
            <img class="absolute -right-[120px] -bottom-[5px]" src="../../assets/images/player_header.png" />
          </div>
        </div>
        <div class="w-full">
          <div class="video-wrapper">
            <iframe
              src="https://player.vimeo.com/video/892391903?h=e2c65c1c9e&color=e25352&title=0&byline=0&portrait=0"
              width="640"
              height="360"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    cardText: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "Welcome to the",
    },
    welcomeText: {
      type: String,
      default: "Partnership Overview",
    },
    overviewHtml: {
      type: String,
      default: "",
    },
  },
  name: "partnership-overview-desktop",
  computed: {
    isDesktop() {
      return window.innerWidth >= 1440
    }
  }
};
</script>
<style lang="scss" scoped>
.partnership-wrapper {}

.partnership {
  @apply gap-[27px];

  h2 {
    font-family: Passion One;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.partnership-description {
  display: block;
  width: 1179px;
  gap: 27px;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  margin-top: 64px;
  line-height: 150%;

  :deep(b) {
    color: #e10600;
    font-weight: 800;
  }

  :deep(strong) {
    color: #fff;
    font-weight: 800;
  }
}

.partnership-title {
  font-family: Passion One;
  font-weight: 700;
  line-height: 26.42px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 24px;

  @media (min-width: 1440px) {
    font-size: 96px;
    line-height: 106px;
    margin-bottom: 64px;
  }

}

.partnership-content {
  font-family: Passion One;
  font-size: 96px;
  font-weight: 700;
  line-height: 106px;
  letter-spacing: 0px;
  width: 1179px;
  display: flex;
  gap: 27px;
}

.partnership-card {
  display: flex;
  width: 390px;
  height: 428px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 20px;
  background: #e10600;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  cursor: pointer;
}

.partnership-play {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 428px;
  width: 762px;
}


.video-wrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
