<template>
  <svg width="67" height="68" viewBox="0 0 67 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7968_24983" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="67" height="68">
<rect x="0.0117188" y="0.634277" width="66.6353" height="66.6353" fill="currentColor"/>
</mask>
<g mask="url(#mask0_7968_24983)">
<path d="M19.4457 61.7164V45.0576H15.0034C13.1524 45.0576 11.5791 44.4098 10.2834 43.1141C8.98769 41.8184 8.33984 40.2451 8.33984 38.3941C8.33984 37.0521 8.71004 35.8143 9.45043 34.6805C10.1908 33.5468 11.1626 32.7023 12.3657 32.147L30.5516 24.0952V22.2905C28.8857 21.689 27.5438 20.6825 26.5257 19.2711C25.5077 17.8598 24.9987 16.2749 24.9987 14.5164C24.9987 12.2027 25.8085 10.236 27.4281 8.61642C29.0477 6.99682 31.0144 6.18701 33.3281 6.18701C35.6418 6.18701 37.6085 6.99682 39.2281 8.61642C40.8477 10.236 41.6575 12.2027 41.6575 14.5164H36.1045C36.1045 13.7298 35.8385 13.0703 35.3063 12.5382C34.7742 12.006 34.1147 11.74 33.3281 11.74C32.5414 11.74 31.882 12.006 31.3498 12.5382C30.8177 13.0703 30.5516 13.7298 30.5516 14.5164C30.5516 15.3031 30.8177 15.9625 31.3498 16.4947C31.882 17.0268 32.5414 17.2929 33.3281 17.2929C34.1147 17.2929 34.7742 17.559 35.3063 18.0911C35.8385 18.6233 36.1045 19.2827 36.1045 20.0694V24.0952L54.2904 32.147C55.4936 32.7023 56.4653 33.5468 57.2057 34.6805C57.9461 35.8143 58.3163 37.0521 58.3163 38.3941C58.3163 40.2451 57.6685 41.8184 56.3728 43.1141C55.0771 44.4098 53.5038 45.0576 51.6528 45.0576H47.2104V61.7164H19.4457ZM15.0034 39.5047H19.4457V36.7282H47.2104V39.5047H51.6528C51.9767 39.5047 52.2428 39.389 52.451 39.1576C52.6593 38.9262 52.7634 38.6254 52.7634 38.2552C52.7634 38.0239 52.7055 37.8272 52.5898 37.6652C52.4742 37.5033 52.3006 37.376 52.0693 37.2835L33.3281 28.9541L14.5869 37.2835C14.3555 37.376 14.182 37.5033 14.0663 37.6652C13.9506 37.8272 13.8928 38.0239 13.8928 38.2552C13.8928 38.6254 13.9969 38.9262 14.2051 39.1576C14.4134 39.389 14.6795 39.5047 15.0034 39.5047Z" fill="currentColor"/>
</g>
</svg>

</template>

<script>
export default {
  name: "icon-hanger",
};
</script>
