<template>
  <div>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 52 317"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="27.1083"
        y1="-4.84454e-08"
        x2="27.1083"
        y2="80"
        stroke="white"
        stroke-width="8"
      />
      <line
        x1="27.1083"
        y1="237"
        x2="27.1083"
        y2="317"
        stroke="white"
        stroke-width="8"
      />
      <rect
        x="26.0039"
        y="133"
        width="35.4657"
        height="35.4657"
        transform="rotate(45 26.0039 133)"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "separator-bar",
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1440px) {
  line {
    stroke-width: 2;
  }

}
</style>
