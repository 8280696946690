<template>
  <section class="actions-section">
    <!-- <h1 class="w-fit 2xl:left-[133px]">{{ title }}</h1> -->
    <div class="title-header px-[96px] py-[37px] items-center justify-center text-white text-center">
      <h1 class="text-[64px] font-[800] uppercase mb-[20px]">Nossas ações</h1>
    </div>
    <div class="action-grid relative mt-14">
      <div class="soccer-field-bg flex justify-center">
        <svg width="886" height="892" viewBox="0 0 886 892" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000498056 0L0.000488281 1V223V224H336H337H446.641V340.009L446.472 340.009C388.793 340.009 342.036 386.767 342.036 444.445C342.036 502.124 388.793 548.881 446.472 548.881L446.641 548.881V666.856H447.993V548.87C504.97 548.057 550.908 501.616 550.908 444.445C550.908 387.275 504.97 340.833 447.993 340.02V224H550H551H886V223V1V0H885H551H550H337H336H291H285H1.00049H0.000498056ZM336 1H291H285H1.00049L1.00048 223H336V1ZM337 223H446.641V222.856H447.993V223H550V1H337V223ZM551 1V223H885V1H551ZM446.472 341.361L446.641 341.361V547.529L446.472 547.529C389.54 547.529 343.388 501.377 343.388 444.445C343.388 387.513 389.54 341.361 446.472 341.361ZM549.556 444.445C549.556 500.869 504.224 546.705 447.993 547.518L447.993 341.372C504.224 342.185 549.556 388.021 549.556 444.445ZM550 668V891H337V668H550ZM336 891V668H1L0.99999 891H336ZM0 891V892H0.99999H336H337H550H551H885H886V891V668V667H885H1H9.89437e-06L0 668V891ZM885 891V668H551V891H885Z" fill="white"/>
        </svg>
      </div>
      <ul class="flex flex-row flex-wrap">
        <campaign-card
          v-for="(campaign, index) in campaigns"
          :title="campaign.title"
          :text="campaign.text"
          :link="campaign.link"
          :text-long="campaign.textLong"
          :image="`./img/${campaign.image}`"
          :key="index"
          :pkey="index"
        >
        </campaign-card>
      </ul>
    </div>
  </section>
</template>
<script>
import CampaignCard from "./CampaignCard.vue";
export default {
  name: "marketing-campaigns-grid",
  components: {
    CampaignCard,
  },
  props: {
    title: {
      type: String,
      default: "Nossas Ações",
    },
    campaigns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.actions-section {
  position: relative;
  margin-top: 162px;
  margin-bottom: 200px;

  .title-header{
    background: var(--color-red);
    line-height: normal;
  }

  &::before {
    content: '';
    display: block;
    inset: 0;
    top: 62px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.3) 50%, #000 100%), url(../../assets/images/grass.jpg), lightgray 50%/cover no-repeat;
    z-index: -2;
    opacity: 0.3;
    mix-blend-mode: luminosity;
  }
  .action-grid {
    position: relative;
    ul {
      padding: 0 62px;
      margin: 0;
      list-style: none;
      row-gap: 58px;
      position: relative;
      z-index: 3;
    }
    .soccer-field-bg {
      position: absolute;
      top: 94px;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }
}
</style>
