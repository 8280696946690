export default [
  {
    "question": "O que são “naming rights”?",
    "answer": `
      <p>Os Direitos de Nome (inglês: naming rights) é o direito sobre a propriedade de nomes de uma marca ou empresa.</p>
      <p> A prática da concessão de direitos de nome é bastante comum entre empresas de diferentes setores, que compram ou alugam o nome de algum estabelecimento, espetáculos culturais e/ou esportivos, trocando para o nome da própria empresa ou de algum produto relacionado.</p>
      <p>No futebol brasileiro, essa prática já existe em relação ao nome de estádios pertencentes a clubes esportivos.</p>
      <p>No caso da proposta do Fatal Model ao Esporte Clube Vitória, a ideia é ter direito de alugar o nome do Clube e associá-lo à marca da plataforma, ou seja: em vez de Esporte Clube Vitória, durante o período de vigência do contrato, o clube passa a se chamar: Fatal Model Vitória.</p>
      <p>
        <em>Importante: o escudo, as cores e o mascote não serão alterados.</em>
      </p>`
  },
  {
    "question": "O que o Fatal Model pretende oferecer exatamente ao Esporte Clube Vitória nesta proposta?",
    "answer": `
      <p>O Fatal Model pretende investir um total de R$200 milhões para o Vitória em troca de ligar seu nome ao Leão a partir da aquisição do Naming Rights do clube, durante 10 anos. Assim, o Esporte Clube Vitória será <em><strong>Fatal Model Vitória</strong></em>, durante o período de vigência do contrato.</p>`
  },
  {
    "question": "Qual o objetivo do Fatal Model com essa proposta?",
    "answer": `
    <p>O Fatal Model busca romper paradigmas, quebrar tabus e preconceitos, sempre de forma respeitosa, para trazer mais dignidade à profissão de acompanhante.</p>
    <p>A cada patrocínio, a cada novo passo, mostramos que é possível educar a sociedade e conscientizar o público em geral que a profissão de acompanhante é digna de respeito, como qualquer outra.</p>
    <p>É por entender seu papel dentro da sociedade que o Fatal Model busca expor sua marca de forma ainda mais ampla, ao associar-se com o nome do Vitória.</p>
    <p>Lembramos que toda a nossa comunicação, em absolutamente todos os materiais de divulgação, é sempre feita de maneira respeitosa, de forma a propagar os valores da marca: Respeito, Segurança e Dignidade.</p>`
  },
  {
    "question": "Como o Torcedor do Leão participará da decisão?",
    "answer": `
    <p>A parceria entre Fatal Model e EC Vitória sempre foi pautada no respeito e no espaço para diálogos.</p>
    <p>Por isso, a marca quer apresentar a proposta para que os torcedores possam opinar se querem que o Clube abra negociações ou não sobre esse tema.</p>
    <p>Os mais de 30 mil sócios torcedores do clube terão a oportunidade de opinar e ajudar a definir o futuro de uma negociação do seu time de coração.</p>
    <p><strong>A votação será realizada entre os dias 01 e 10 de dezembro</strong>, e o resultado será o veredito final para o avanço ou não da negociação.</p>
    <p>Na enquete disponibilizada no Portal, o sócio-torcedor deverá responder SIM ou NÃO à seguinte pergunta: </p>
    <p>'<strong>Você acha que o Vitória deve abrir espaço para negociação dos “Naming Rights” do Clube com o Fatal Model?</strong>'</p>
    <p>Caso a maioria dos sócio-torcedores votantes escolham a opção SIM, o Esporte Clube Vitória abrirá espaço para a negociação da proposta.</p>
    <p>Caso a maioria dos sócio-torcedores votantes escolham a opção NÃO, respeitaremos a decisão da maioria e não prosseguiremos na negociação dos “naming rights” do Clube; independente disso, o Fatal Model seguirá apoiando o Leão de outras formas.</p> `
  },
  {
    "question": "E se eu não for Sócio-Torcedor?",
    "answer": `
    <p>Além da votação para os sócios, os demais torcedores poderão apoiar a causa via <strong>Petição Aberta Online</strong>. Para assinar, <u><a target="_blank" href="https://secure.avaaz.org/community_petitions/po/presidente_fabio_mota_vote_e_opine_sobre_o_maior_investimento_da_historia_do_esporte_clube_vitoria_1/?cswBJtb&utm_source=sharetools&utm_medium=copy&utm_campaign=petition-1716773-vote_e_opine_sobre_o_maior_investimento_da_historia_do_esporte_clube_vitoria&utm_term=cswBJtb%2Bpo">clique aqui</a></u>.</p>
    <p>É uma forma de torcedores e público em geral poderem demonstrar seu apoio à iniciativa de negociação da associação das duas marcas.</p>
    <p>Além disso, você também pode debater a pauta Comentando e compartilhando nas redes sociais usando #ColossalFatalModel.</p>
    <p>Vale reforçar que apenas a votação realizada para os sócios-torcedores tem poder para determinar o avanço ou não da negociação e proposta apresentada pelo Fatal Model.</p>`
  },
  {
    "question": "Se a maioria dos Sócio-Torcedores forem a favor, o investimento pelo Fatal Model está garantido?",
    "answer": `
    <p>Neste primeiro momento, precisamos que o Clube esteja disposto a pelo menos ouvir, avaliar e considerar a proposta do Fatal Model.</p>
    <p> Isso irá acontecer se a torcida estiver disposta a apoiar a parceria.</p>
    <p>Caso contrário, o E. C. Vitória não irá nem mesmo avaliar nossa ideia e formato de investimento.</p>
    <p>A partir do SIM da maioria dos sócio-torcedores, o E.C. Vitória, no papel de seus dirigentes, estará disposto a receber, avaliar e negociar a proposta de cessão de direitos de uso do nome junto ao Fatal Model.</p>
    <p><strong>Este é o primeiro passo para uma negociação disruptiva, inovadora e histórica.</strong></p>`
  }
]
