<template>
  <section class="naming-disclaimer mt-[230px]">
    <div class="flex flex-row">
      <h1 class="text-[96px] font-[700] px-[80px]">
        Mas lembre-se, torcedor rubro-negro, se a proposta avançar...
      </h1>
    </div>
    <div
      class="grid grid-cols-3 relative justify-between 2xl:px-[90px] 2xl:items-start w-full mt-[80px]"
    >
      <div class="content-block content-block--top mt-[68px]">
        <div class=" max-w-[400px] text-[24px]">
          <p>O escudo. As cores. Os mascotes.</p>
          <p>
            <strong class="block text-[32px] font-[800] my-[10px]"
              >Nada disso mudará.</strong
            >
          </p>
          <p class="mb-[10px]">
            Não se trata da mudança de escudo, cores e mascote.<br />
          </p>
          <p>
            Não se trata da aquisição do clube por outra empresa.
          </p>
        </div>
        <div class="stripe stripe--left"></div>
      </div>

      <div class="content-block--middle">
        <img
          src="../../assets/images/player_bottom.png"
          alt="Um jogador do Vitória com as mãos para trás"
        />
      </div>

      <div class="content-block content-block--bottom">
        <div class="stripe stripe--right"></div>
        <p class="relative left-[60px] mt-[220px] text-[24px] max-w-[400px]">
          <strong class="block mb-[20px]">E não mudará também a importância da torcida.</strong>
          Fundamentado no respeito e carinho que o torcedor sempre teve conosco,
          queremos, antes de iniciar essa conversa, saber a sua opinião sobre o
          projeto de negociação dos naming rights do clube.
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "partnership-progress",
  components: {},
  props: {
    bottomTitle: {
      type: String,
      default: "Renov<span>ação</span>",
    },
    ctaButtonLabel: {
      type: String,
      default: "Clique aqui e assista ao vídeo!",
    },
  },
};
</script>
<style lang="scss" scoped>
.naming-disclaimer {
  h1 {
    font-family: Passion One;
    line-height: normal;
  }
}
.content-block {
  .header-text {
    color: #fff;
    text-align: center;
    font-family: Passion One;
    font-size: 48px;

    font-weight: 700;
    line-height: normal;
    &--stroked {
      -webkit-text-stroke: 1px #fff;
      -webkit-text-fill-color: transparent;
      color: transparent;
    }
    strong {
      font-weight: 700;
    }

    font-family: Passion One;
    font-size: 108px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    :deep(span) {
      color: var(--color-red);
    }
  }
  .stripe {
    position: absolute;
    width: 50%;
    height: 80px;
    z-index: -1;
    &--left {
      background-color: var(--color-red);
      left: 0;
      top: 380px;
    }
    &--right {
      background-color: var(--color-red);
      right: 0;
      top: 110px;
    }
  }
  &--top {
    strong {
      color: var(--color-red);
    }
  }
  &--middle {
    width: 470px;
    z-index: 10;
    img {
      position: relative;
      left: -50px;
      top: -10px;
    }
  }
  &--bottom {
    strong {
      color: var(--color-red);
    }
  }
}
</style>
