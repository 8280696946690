<template>
    <section class="relative w-full flex flex-col justify-center items-center mt-24 2xl:mt-[240px]">
        <h2 class="uppercase text-sm 2xl:text-[52px] mb-1 2xl:mb-[24px]">Esporte Clube Vitória será</h2>
        <h1 class="uppercase text-[22px] 2xl:text-[92px] font-[800] mb-6 2xl:mb-[100px]">Fatal Model Vitória</h1>
        <div>
            <fatal-butterfly v-if="isDesktop" />
            <fatal-butterfly v-else :width="46" :height="31" />
        </div>
        <div>
            <official-shield v-if="isDesktop" />
            <official-shield v-else :width="90" :height="105" />
        </div>
        <div class="hidden 2xl:absolute 2xl:opacity-30 2xl:-z-10 2xl:-right-[50%] 2xl:bottom-[400px]">
            <bg-shield class="w-full" />
        </div>
    </section>
</template>

<script>
import BgShield from '../Icons/BgShield.vue'
import OfficialShield from './OfficialShield.vue'
import FatalButterfly from './FatalButterfly.vue'
    export default {
        name: 'NamePresentation',
        components: {
            OfficialShield,
            FatalButterfly,
            BgShield
        },
        computed: {
          isDesktop() {
            return window.innerWidth >= 1440
          },
        },
    }
</script>

<style lang="scss" scoped>

</style>
