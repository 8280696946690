<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1764 12.0025L6.29641 15.8825C5.90641 16.2725 5.91641 16.9125 6.29641 17.3025C6.68641 17.6925 7.31641 17.6925 7.70641 17.3025L12.2964 12.7125C12.6864 12.3225 12.6864 11.6925 12.2964 11.3025L7.70641 6.7125C7.31641 6.3225 6.68641 6.3225 6.29641 6.7125C5.90641 7.1025 5.90641 7.7325 6.29641 8.1225L10.1764 12.0025ZM16.1764 12.0025L12.2964 15.8825C11.9064 16.2725 11.9164 16.9125 12.2964 17.3025C12.6864 17.6925 13.3164 17.6925 13.7064 17.3025L18.2964 12.7125C18.6864 12.3225 18.6864 11.6925 18.2964 11.3025L13.7064 6.7125C13.3164 6.3225 12.6864 6.3225 12.2964 6.7125C11.9064 7.1025 11.9064 7.7325 12.2964 8.1225L16.1764 12.0025Z"
      fill="#E10600"
    />
  </svg>
</template>

<script>
export default {
  name: "double-chevron",
};
</script>
