export default {
  sponsorhip: "Patrocínio",
  team: "E.C. Vitória",
  teamShort: "Vitória",
  welcome: "SEJA BEM-VINDO!!!",
  welcomeText: "Conheça os principais capítulos dessa história!",
  overviewHtml: `
      Para o Fatal Model, patrocinar o <b>Vitória</b> vai <strong>muito além de
      uma relação comercial</strong>. É <strong>muito mais do que um patrocínio</strong>.
      É estar <b>lado a lado</b>. Vibrar junto. Ganhar junto.
      Fazer a diferença. <strong>Escrever a história</strong>.`,
  actionsTitle: "Nossas Ações",
  actionList: [
    {
      title: "Anúncio da Parceria",
      text: "Com repercussão mundial, mostrou o pioneirismo da ação e um gesto histórico de empoderamento.",
      textLong:
        "O material teve repercussão mundial e mostrou o pioneirismo da ação e contou com um gesto histórico de empoderamento: o cumprimento entre a acompanhante Nina Sag e o presidente rubro-negro Fábio Mota.",
      link: "https://www.youtube.com/watch?v=cfc0sh0XwTA",
      image: "marketing_actions/001.png",
    },
    {
      title: "Coletiva de Imprensa",
      text: "Coletiva realizada no Barradão serviu para que Nina Sag e Fábio Mota explicassem a parceria.",
      textLong:
        "Diante da sala tomada por jornalistas, a coletiva de imprensa realizada no Barradão serviu para que a acompanhante Nina Sag e o presidente rubro-negro Fábio Mota explicassem os objetivos da parceria.",
      link: "https://www.flickr.com/photos/ecvitoria/albums/72177720305920074",
      image: "marketing_actions/002.png",
    },
    {
      title: "Pontapé inicial",
      text: "Nina Sag foi responsável pelo pontapé inicial na partida que marcou o início da parceria.",
      textLong:
        "Nina Sag foi responsável pelo pontapé inicial na partida que marcou o início da parceria.",
      link: "https://www.flickr.com/photos/ecvitoria/52685913674/in/album-72177720305984239/",
      image: "marketing_actions/003.png",
    },
    {
      title: "Sorteio de Ingressos",
      text: "Desde o começo da parceria, Fatal realiza sorteios de ingressos para partidas realizadas no Barradão.",
      textLong:
        "De modo a retribuir todo o apoio da torcida, desde o começo da parceria o Fatal Model realiza recorrentemente um grande sorteio de ingressos para as partidas realizadas no Barradão.",
      link: "https://www.flickr.com/photos/ecvitoria/53112484157/in/album-72177720310448647/",
      image: "marketing_actions/004.png",
    },
    {
      title: "Desafio Dia das Mães",
      text: "No Dia das Mães, um desafio foi lançado a fim de combater uma expressão muito entoada nos estádios.",
      textLong:
        "No Dia das Mães, um desafio foi lançado a fim de combater uma expressão muito entoada nos estádios.",
      link: "https://www.instagram.com/p/CsO3pkNA_6W/",
      image: "marketing_actions/005.png",
    },
    {
      title: "Desconto para Sócios",
      text: "Com Vampeta e Bruna Surfistinha, foi anunciado desconto de 90% para sócios no Fatal Premium.",
      textLong:
        "Uma das solicitações mais recorrentes é o desconto no plano Fatal Premium. Com o ídolo Vampeta e a escritora Raquel Pacheco (Bruna Surfistinha) isso se materializou com um desconto de 90% para sócios.",
      link: "https://www.instagram.com/p/CxV0Bc0AnsZ/",
      image: "marketing_actions/006.png",
    },
    {
      title: "Caçada Fatal",
      text: "Ao espalhar pelas ruas 100 camisas oficiais do Vitória, o Fatal levou mais de 4 mil pessoas às ruas.",
      textLong:
        "Ao espalhar pelas ruas de Salvador 100 camisas oficiais do Vitória, o Fatal Model levou mais de 4 mil pessoas às ruas para participar da dinâmica.",
      link: "https://www.instagram.com/p/CxizAoprHsk/",
      image: "marketing_actions/007.png",
    },
    {
      title: "Aniversário do Barradão",
      text: "Homenagens em ocasiões especiais são marcas do Fatal Model para com seus parceiros.",
      textLong:
        "A conexão entre Fatal e Vitória é tamanha que a plataforma faz questão de estar ao lado do clube em ocasiões especiais. Como no aniversário do Barradão.",
      link: "https://www.instagram.com/reel/Czga6GOrGHV/?igshid=NzBmMjdhZWRiYQ==",
      image: "marketing_actions/008.png",
    },
    {
      title: "Mês da Acompanhante",
      text: "Uma braçadeira reforçando a data foi distribuída a atletas e também sorteada a torcedores do clube.",
      textLong:
        "Dia da Acompanhante foi comemorado ao longo de um mês inteiro pelo Fatal Model. Por isso, uma braçadeira reforçando a data foi distribuída a atletas e também sorteada a torcedores do clube.",
      link: "https://www.instagram.com/p/Cs_naxYM6KF/",
      image: "marketing_actions/009.png",
    },
    {
      title: "Patrocínio Basquete",
      text: "O patrocínio ao basquete do Leão foi outra demonstração de que a parceria vai muito além dos campos.",
      textLong:
        "O patrocínio do Fatal ao basquete do Vitória foi outra demonstração de que a parceria vai muito além dos campos de futebol.",
      link: "https://www.instagram.com/reel/CvhsK2YgTZ1/",
      image: "marketing_actions/010.png",
    },
    {
      title: "Auxílio na contratação de atletas",
      text: "Fatal contribuiu com a chegada dos reforços Giovanni Augusto e Matheusinho para a Série B.",
      textLong:
        "O Fatal Model apresenta-se como mais do que um simples patrocinador. É um parceiro do Vitória. Foi por isso que contribuiu com a chegada dos reforços Giovanni Augusto e Matheusinho para a Série B.",
      link: "https://www.flickr.com/photos/ecvitoria/52991608785/in/album-72177720309218161/",
      image: "marketing_actions/011.png",
    },
    {
      title: "Dia da Mulher",
      text: "Fatal Model cedeu o seu espaço na camisa do Vitória para fazer homenagens a mulheres.",
      textLong:
        "Na data em que é celebrado o Dia da Mulher, o Fatal Model cedeu o seu espaço na camisa do Vitória para homenagear mulheres guerreiras.",
      link: "https://www.instagram.com/reel/Cqshfe_rUtD/",
      image: "marketing_actions/012.png",
    },
    {
      title: "Sorteio de camiseta oficial",
      text: "Inúmeras ações são criadas pensando no torcedor. No aniversário do Vitória, não foi diferente.",
      textLong:
        "Inúmeras ações são criadas pensando no mais valioso patrimônio do clube: o torcedor. No aniversário do Vitória, no dia 13 de maio de 2023, não foi diferente, com um sorteio de camisa oficial.",
      link: "https://www.instagram.com/p/CsMDggBArFg/",
      image: "marketing_actions/013.png",
    },
  ],
  footerText: "Patrocínio até final de 2024",
  footerSince: "<span>*</span> Dados de 05/09/2023",
};
