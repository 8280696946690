<template>
  <svg
    width="67"
    height="68"
    viewBox="0 0 67 68"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.91503 34.2585C6.91503 49.8376 19.417 62.0233 34.7018 62.0233C49.9865 62.0233 62.4823 49.5375 62.4823 35.0606C62.4823 18.9904 49.7795 6.4939 34.7018 6.4939C28.4056 6.4939 22.6066 8.34592 18.0276 12.0479C16.6117 13.1585 16.5213 15.3488 17.7866 16.6445C18.8711 17.7551 20.5581 17.8476 21.7631 16.8913C25.3179 14.0223 29.8516 12.6638 34.7018 12.6638C47.2075 12.6638 56.4573 22.9984 56.4573 35.0606C56.4573 45.372 47.2075 55.8534 34.7018 55.8534C22.196 55.8534 12.9401 45.3721 12.9401 34.2585H18.3325C19.6881 34.2585 20.3509 32.5926 19.417 31.6363L11.0121 22.9984C10.4096 22.3814 9.47567 22.3814 8.87317 22.9984L0.46822 31.6363C-0.495788 32.5926 0.166967 34.2585 1.5226 34.2585H6.91503ZM44.4352 39.3073V39.2346C44.4352 34.9397 41.595 33.1407 36.5728 31.8398C32.2905 30.7484 31.2293 30.217 31.2293 28.5988V28.5283C31.2293 27.3312 32.3261 26.3787 34.4151 26.3787C36.1146 26.3787 37.8158 26.9808 39.6236 28.0369C40.0422 28.2817 40.4677 28.4247 40.9993 28.4247C42.4129 28.4247 43.5512 27.3312 43.5512 25.9222C43.5512 24.8642 42.9444 24.1187 42.3395 23.7727C40.7818 22.7828 39.0163 22.1305 37.069 21.8106V19.9587C37.069 18.6602 36.0188 17.6106 34.7116 17.6106C33.409 17.6106 32.3568 18.6578 32.3568 19.9587V21.7953C28.449 22.5118 25.8106 25.2059 25.8106 29.0179V29.0884C25.8106 33.9876 29.034 35.3634 34.0229 36.6287C38.1634 37.6891 39.0163 38.3904 39.0163 39.7638V39.8343C39.0163 41.2802 37.6719 42.1601 35.4432 42.1601C33.0328 42.1601 30.9839 41.3135 29.1007 39.9421C28.7506 39.6929 28.2566 39.4505 27.5475 39.4505C26.134 39.4505 25 40.5371 25 41.9504C25 42.7948 25.4253 43.5731 26.0302 43.992C27.9462 45.3589 30.1218 46.2255 32.3593 46.6444V48.5824C32.3593 49.8787 33.4116 50.9283 34.7142 50.9283C36.0191 50.9283 37.0715 49.8787 37.0715 48.5824V46.8364C41.4711 46.294 44.4352 43.6528 44.4352 39.3073Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-finance",
};
</script>
