<template>
  <div class="stats-wrapper w-full mt-12 2xl:mt-0">
    <div class="stats-title w-full text-center">
      <h1 class="text-xl font-extrabold 2xl:text-[53px]">
        O SUCESSO DE 2023
      </h1>
      <p class="text-md 2xl:text-[40px] mt-3 2xl:mt-6 2xl:w-[947px] mx-auto">Reviva os momentos inesquecíveis da parceria Fatal Model e Vitória em 2023</p>
    </div>
    <div class="stats-container mt-8 2xl:mt-20">
      <div class="stats-grid">
        <statistic-cell
          v-for="(statistic, index) in stats.grid"
          :key="`statistic-${index}`"
          :statistic="statistic"
          :total-days="totalDays()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StatisticCell from "./StatisticCell.vue";

export default {
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  name: "partnership-stats",

  components: {
    StatisticCell,
  },
  methods: {
    totalDays() {
      const today = new Date();
      const initialDate = new Date(2023, 2, 10);
      const diff = Math.abs(today - initialDate);
      const totalDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      return totalDays;
    },
  },
};
</script>
<style lang="scss">
.stats-wrapper {
  @apply flex flex-col justify-center items-center mb-[28px];

  @media screen and (min-width: 1440px) {
    @apply  mb-[70px]
  }
}

.stats-title {
  color: #fff;
  line-height: normal;
  background-color: #e10600;
  padding: 20px 30px;
  align-self: flex-start;
  text-align: left;
  & svg {
    margin: 0 7px 0 0;
    float: left;
  }
  @media (min-width: 1440px) {
    padding: 36px;
  }
}

.stats-grid {
  @apply grid grid-cols-1 2xl:grid-cols-2 justify-center items-center gap-y-[10px];

  @media screen and (min-width: 1440px) {
    @apply gap-y-[40px];
  }
}

.stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1440px) {
    justify-content: end;
    @apply w-[1180px];
  }
}
</style>
