<template>
  <div class="marketing-campaigns-grid-mobile">
    <h1>
      <double-chevron />
      {{ title }}
    </h1>
    <carousel>
      <slide v-for="(campaign, index) in campaigns" :key="index">
        <div class="carousel__item">
          <a :href="campaign.link" rel="noopener noreferrer" class="no-underline">
            <img :src="`./img/${campaign.image}`" :alt="campaign.title" />
            <h2>{{ campaign.title }}</h2>
          </a>
          <p class="text-left">{{ campaign.textLong }}</p>
        </div>
      </slide>
      <template #addons>
        <navigation>
          <template #next>
            <span
              ><svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="10" fill="#E10600" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.41517 3.68412C6.97098 4.14981 6.97098 4.90207 7.41517 5.36776L11.8343 10.0008L7.41517 14.6338C6.97098 15.0995 6.97098 15.8517 7.41517 16.3174C7.85937 16.7831 8.57691 16.7831 9.0211 16.3174L14.2489 10.8366C14.6931 10.3709 14.6931 9.61866 14.2489 9.15297L9.0211 3.67218C8.5883 3.21843 7.85937 3.21843 7.41517 3.68412Z"
                  fill="#FAFAFA"
                />
              </svg>
            </span>
          </template>
          <template #prev>
            <span
              ><svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="20"
                  y="20"
                  width="20"
                  height="20"
                  rx="10"
                  transform="rotate(-180 20 20)"
                  fill="#E10600"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5848 16.3159C13.029 15.8502 13.029 15.098 12.5848 14.6323L8.16569 9.99927L12.5848 5.36626C13.029 4.90057 13.029 4.14831 12.5848 3.68262C12.1406 3.21693 11.4231 3.21693 10.9789 3.68262L5.75111 9.16342C5.30692 9.6291 5.30692 10.3814 5.75111 10.8471L10.9789 16.3279C11.4117 16.7816 12.1406 16.7816 12.5848 16.3159Z"
                  fill="#FAFAFA"
                />
              </svg>
            </span>
          </template>
        </navigation>

        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import DoubleChevron from "../Icons/DoubleChevron.vue";

export default {
  name: "MarketingCampaignsGridMobile",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    DoubleChevron,
  },
  props: {
    title: {
      type: String,
      default: "Nossas Ações",
    },
    campaigns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.marketing-campaigns-grid-mobile {
  h1 {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 800;
    margin: 28px 0 20px;
    svg {
      margin: 0 7px 0 20px;
    }
  }
}
.carousel {
  --carousel-color-primary: var(--color-red);
  &__pagination-button {
    background-color: red;
    &--active {
      background-color: var(--color-red);
    }
  }

  :deep(&__prev) {
    top: 70px;
    left: 61px;
    &--disabled {
      display: none;
    }
  }

  :deep(&__next) {
    top: 70px;
    right: 61px;
    &--disabled {
      display: none;
    }
  }

  &__slide {
    align-items: flex-start;
  }

  &__item {
    max-width: 243px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    font-family: Montserrat, sans-serif;
    h2 {
      padding: 4px 12px;
      max-height: 40px;
      background-color: var(--color-red);
      font-weight: 800;
      font-size: 11px;
      color: #fff;
      border-radius: 16px;
      align-self: flex-start;
      margin-top: 16px;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 27px;
    }
    p {
      margin-top: 1rem;
      text-align: left;
      font-size: 12px;
      line-height: 18px;
    }
  }
  :deep(&__pagination-button) {
    &::after {
      background-color: #ffffff80;
      border-radius: 3px;
    }
    &--active {
      &::after {
        background-color: var(--color-red);
      }
    }
  }
}
</style>
