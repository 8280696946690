export default {
  title: "Nossos Números",
  grid: [
    {
      icon: "IconDate",
      result: "totalDays",
      title: "dias de parceria <span>*</span>",
    },
    {
      icon: "IconTicket",
      result: "+ 300",
      title: "ingressos sorteados",
    },
    {
      icon: "IconBalls",
      result: "2",
      title: "modalidades patrocinadas",
    },
    {
      icon: "IconFinance",
      result: "30 Milhões",
      title: "de retorno publicitário apenas em Fevereiro",
    },
    {
      icon: "IconPlayer",
      result: "2 jogadores",
      title: "contratados com apoio do Fatal model",
    },

    {
      icon: "IconHanger",
      result: "Maior patrocinador",
      title: "da história das mangas do Vitória",
    },
  ],
};
