<template>
  <section class="welcome-banner">
    <animation-fatal-vitoria class="animation-fatal-vitoria" />
  </section>
</template>
<script>
import AnimationFatalVitoria from "./AnimationFatalVitoria.vue";

export default {
  name: "welcome-banner",
  components: {
    AnimationFatalVitoria,
  },
};
</script>
<style lang="scss">
.welcome-banner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &::after {
    content: none;
    position: absolute;
    width: 1640px;
    height: 670px;
    --angle: 145deg;
    --offset: 1093px;
    --color: #e25352;
    --space: 84px;
    --width-1: 4px;
    --width-2: 8px;
    --width-3: 12px;
    --width-4: 16px;
    --stop-1: calc(var(--offset) + var(--width-1));
    --start-2: calc(var(--stop-1) + var(--space));
    --stop-2: calc(var(--stop-1) + var(--space) + var(--width-2));
    --start-3: calc(var(--stop-2) + var(--space));
    --stop-3: calc(var(--stop-2) + var(--space) + var(--width-3));
    --start-4: calc(var(--stop-3) + var(--space));
    --stop-4: calc(var(--stop-3) + var(--space) + var(--width-4));
    background-image: repeating-linear-gradient(
      var(--angle),
      transparent 0 var(--offset),
      var(--color) var(--offset) var(--stop-1),
      transparent var(--stop-1) var(--start-2),
      var(--color) var(--start-2) var(--stop-2),
      transparent var(--stop-2) var(--start-3),
      var(--color) var(--start-3) var(--stop-3),
      transparent var(--stop-3) var(--start-4),
      var(--color) var(--start-4) var(--stop-4)
    );
    z-index: -2;
  }

  .header-player-img {
    left: 66px;
    z-index: -10;
    top: 106px;
    width: 273px;
  }
  @media screen and (min-width: 1440px) {
    padding-top: 100px;

    max-width: 1440px;



    .header-player-img {
      display: none;
    }

    &::after {
      content: "";
      background-position: 100% 100%;
    }
  }
}
</style>
