<template>
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.493 11.54H56.2694C59.3236 11.54 61.8224 14.0388 61.8224 17.0929V55.9635C61.8224 59.0176 59.3236 61.5165 56.2694 61.5165H11.8459C8.79179 61.5165 6.29297 59.0176 6.29297 55.9635V17.0929C6.29297 14.0388 8.79179 11.54 11.8459 11.54H14.6224V8.76353C14.6224 7.23647 15.8718 5.98706 17.3989 5.98706C18.9259 5.98706 20.1753 7.23647 20.1753 8.76353V11.54H47.94V8.76353C47.94 7.23647 49.1894 5.98706 50.7165 5.98706C52.2436 5.98706 53.493 7.23647 53.493 8.76353V11.54ZM14.6224 55.9635H53.493C55.02 55.9635 56.2694 54.7141 56.2694 53.1871V22.6459H11.8459V53.1871C11.8459 54.7141 13.0953 55.9635 14.6224 55.9635ZM25.73 30.9753H20.1771V36.5283H25.73V30.9753ZM36.8359 30.9753H31.283V36.5283H36.8359V30.9753ZM42.3888 30.9753H47.9418V36.5283H42.3888V30.9753ZM25.73 42.0812H20.1771V47.6342H25.73V42.0812ZM31.283 42.0812H36.8359V47.6342H31.283V42.0812ZM47.9418 42.0812H42.3888V47.6342H47.9418V42.0812Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-date",
};
</script>
